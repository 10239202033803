@import '../../assets/variables';

.project-page{
  max-width: 70%;
  font-family: "Poppins", sans-serif;
}

.stack{
  margin-bottom: 10px;
}
.project-title{
  font-size: 38px;
  margin-left: 16px;
  color: $black;
}

@media (min-width: 901px) {
  .project-div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 16px;
    background: white;
    box-shadow: 0 3px 5px 2px rgba(0, 0, 0, .1);
    padding: 4px;
    width: 100%;
    margin: 16px;


    .project-description {
      width: 70%;
      color: $black;
      p {
        font-weight: 400;
        font-size: 16px;
      }

      a {
        text-decoration: none;
        color: $purple;
        svg {
          padding-left: 8px;
        }
      }
    }

    img {
      width: 225px;
      height: auto;
      margin-left: 30px;
    }
  }
}