@import '../../assets/variables';

@media (min-width: 901px) {
  .nav-bar {
    background: #eaeaeb;
    width: 120px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
      display: block;
      padding: 8px 0;
      width: 100%;
      height: auto;
      margin: 8px auto;
    }

    nav {
      display: block;
      text-align: center;
      position: absolute;
      height: 210px;
      top: 50%;
      margin-top: -120px;
      width: 100%;
      a {
        font-size: 26px;
        color: $icon;
        display: block;
        line-height: 50px;
        height: 50px;
        position: relative;
        text-decoration: none;
        margin: 8px;
        i {
          transition: all 0.3s ease-out;
        }

        &:hover {
          color: #47509a;

          svg {
            opacity: 0;
          }

          &::after {
            opacity: 1;
          }
        }

        &::after {
          content: '';
          font-size: 16px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          transition: all 0.3s ease-out;
        }
      }

      a.home-link {
        &::after {
          content: 'HOME';
          font-family: "Poppins", sans-serif;
        }
      }

      a.about-link {
        &::after {
          content: 'ABOUT';
          font-family: "Poppins", sans-serif;
        }
      }

      a.contact-link {
        &::after {
          content: 'CONTACT';
          font-family: "Poppins", sans-serif;
        }
      }

      a.project-link {
        &::after {
          content: 'PROJECTS';
          font-family: "Poppins", sans-serif;

        }
      }

      a.active {
        svg {
          color: $purple;
        }
      }
    }

    .ext-links {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;
      text-decoration: none;
      margin-bottom: 25%;
      a {
        padding: 7px 0;
        color: $icon;
        display: block;
        font-size: 22px;
        line-height: 26px;
        &:hover svg {
          color: $purple;
        }
      }
    }
  }
}
