@import '../../assets/variables';

@media (min-width: 901px) {
  .page {
    width: 100%;
    height: 100%;
  }

  .container {
    width: 100%;
    // will-change: contents;
    height: 90%;
    min-height: 560px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 0.3s;

    .large-logo {
      position: absolute;
      left: 45%;
      top: 5%;
      // margin-right: 20%;
      img {
        width: 100%;
      }
    }
  }

  .project-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 5%;
  }

  .project-page {
    color: $white;
    left: 20%;
    top: 8.5%;
    // position: absolute;
   
  }
  .project-description{
    h1 {
      font-size: 28px;
      // letter-spacing: 4px;
      margin-bottom: 5%;
    }
  }
}
