@import '../../assets/variables';

@media (min-width: 901px) {
  .text-zone-about {
    position: absolute;
    text-align: left;
    left: 20%;
    top: 5%;
    transform: trasnlateY(-50%);
    width: 60%;
    max-height: 90%;
    // color: $white;
    color: $black;
    line-height: 28px;
    font-size: 18px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    h1{
      margin-bottom: 10%;
    }
  }
  a:hover {
    opacity: 0.6;
  }
  .large-logo-about {
    left: 60%;
    top: 78%;
    z-index: -1;
    img {
      width: 200px;
      height: auto;
    }
  }
}
