@import '../../assets/variables';



@media (min-width: 901px) {
  .home-page {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin-left: 150px;
    .text-zone {
      position: absolute;
      left: 13%;
      top: 20%;
      transform: trasnlateY(-50%);
      // width: 60%;
      max-height: 90%;
      color: $black;
      font-family: "Poppins", sans-serif;

      h1 {
        width: 60%;
        font-weight: 500;
      }

      h2 {
        color: $black;
        margin-top: 20px;
        font-weight: 400;
        // font-family: sans-serif;
        font-family: "Poppins", sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 2s backwards;
      }

      .button-div {
        display: flex;
        justify-content: space-between;
        width: 65%;
      }

      .flat-button {
        color: $purple;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 4px;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid $purple;
        margin-top: 25px;
        float: left;
        transition: .2s;
        white-space: nowrap;
        border-radius: 14px;
        &:hover {
          background: $purple;
          color: white;
          opacity: 1;
        }
      }
    }
  }

  .first {
    animation: fadeIn 1s 0.4s backwards;
  }

  .second {
    animation: fadeIn 1s 1s backwards;
  }

  .third {
    animation: fadeIn 1s 1.6s backwards;
  }

  .last {
    animation: fadeIn 1s 2.2s backwards;
  }
}


@media(max-width: 1370px){
  .home-page{
    display: flex;
    flex-direction: column;
    .text-zone{
      position: relative;
      width: 60%;
      top: 0;
      left: 25%
    }
    .large-logo{
      position: relative;
      left: 12%;
      top: 0;
      width: 70%;
      img{
        width: 85%;
      }
    }
  }
}