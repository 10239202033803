@import 'animate.css';
@import './assets/variables.scss';




@media (max-width: 900px) {
  .app {
    display: flex;
    height: 100vh;
    overflow: scroll;
    .nav-bar {
      width: 25%;
      overflow: hidden;
      background: #eaeaeb;
      text-align: center;
      height: 100%;
      position: fixed;

      .logo {
        padding: 8px 0;
        width: 100%;
        height: auto;
        margin: 8px auto;
      }

      nav {
        height: 50%;
        text-align: center;
        display: flex;
        flex-direction: column;
        a {

          color: $icon;
          margin-top: 30%;
          font-size: 295%;
          
          i {
            transition: all 0.3s ease-out;
          }
  
          &:hover {
            color: #47509a;
  
            &::after {
              opacity: 1;
            }
          }
  
          &::after {
            content: '';
            font-size: 16px;
            letter-spacing: 2px;
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0;
            transition: all 0.3s ease-out;
          }
        }
        
        a.home-link{
          &::after {
            content: 'HOME';
            font-family: "Poppins", sans-serif;
          }
        }
  
        a.about-link {
          &::after {
            content: 'ABOUT';
            font-family: "Poppins", sans-serif;
          }
        }
  
        a.contact-link {
          &::after {
            content: 'CONTACT';
            font-family: "Poppins", sans-serif;
          }
        }
  
        a.project-link {
          &::after {
            content: 'PROJECTS';
            font-family: "Poppins", sans-serif;
          }
        }
  
        a.active {
          svg {
            color: $purple;
          }
        }
        
      }
      .ext-links {
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        a {
          color: $icon;
          font-size: 280%;
        }
      }
    }
    .page {
      width: 75%;
      margin-left: 15%;
      height: 100%;
    
      .large-logo {
        display: none;
      }
      .container {
        margin-top: 15%;
        margin-left: 5%;
      }
      .text-zone {
        width: 80%;
        max-height: 90%;
        color:$black;
        line-height: 400%;
        font-family: "Poppins", sans-serif;

        h1 {
          font-size: 180%;
          font-weight: 500;
        }

        h2{
          font-weight: 400;
          font-size: 160%;
        }
        .button-div {
          display: flex;
          flex-direction: column;
          width: 80%;
          a {
            color: $purple;
            text-decoration: none;
            font-size: 18px;
          }
          .flat-button {
            text-align: center;
            letter-spacing: 4px;
            font-family: "Poppins", sans-serif;
            text-decoration: none;
            padding: 10px 18px;
            border: 1px solid $purple;
            border-radius: 14px;
            margin: 12px;
            float: left;
            transition: .2s;
            &:hover {
              background: $purple;
              color: white;
              opacity: 1;
            }
          }
        }
      }
      .large-logo {
        padding: 8px 0;
        height: auto;
        margin: 8px auto;
        img {
          width: 10%;
        }
      }
      .text-zone-about {
        color: $black;
        padding: 4px;
        font-family: "Poppins", sans-serif;
        margin-left: 15%;
        p {
          line-height: 240%;
          font-size: medium;
        }
      }
      .large-logo-about {
        margin-left: 10%;
        img {
          width: 55%;
        }
      }
      .contact-page {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: -10%;

      }
      .text-zone-contact {
       font-family: "Poppins", sans-serif;
        height: 50%;
        width: 100%;
        color: $black;
        margin-bottom: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // font-size: 180%;
      }
      .button-div-contact {
        display: flex;
        flex-direction: column;

        a {
          color: $purple;
          text-decoration: none;
          font-size: 18px;
        }

        .flat-button {
          text-align: center;
          letter-spacing: 4px;
          font-family: "Poppins", sans-serif;
          text-decoration: none;
          padding: 10px 18px;
          border: 1px solid $purple;
          border-radius: 14px;
          margin: 12px;
          float: left;
          transition: .2s;
          &:hover {
            background: $purple;
            color: white;
            opacity: 1;
          }
        }
        
      }
      .project-page{
        margin-left: 20%;
      }
      .project-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background: white;
        box-shadow: 0 3px 5px 2px rgba(0, 0, 0, .1);
        padding: 4px;
        margin: 16px;
        border-radius: 16px;
        img {
          width: 60%;
          height: auto;
          margin-left: 20%;
        }

        h1 {
          font-size: 150%;
          a {
            color: $purple;
            text-decoration: none;
            svg {
              padding-left: 8px;
            }
          }
        }
        p {
          font-family: "Poppins", sans-serif;
          color: $black;
          font-size: 130%;
          font-weight: 400;
          line-height: 160%;
        }
      }
    }
  }
}
