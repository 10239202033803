html {
  font-size: 62.5%;
}

body {
  margin: 0;
  color: #e2dfdf;
  background: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: "Poppins", sans-serif;
}
